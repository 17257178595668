import BaseBean from "@/utils/BaseBean";

export interface ICxGghCardDataObj {
    utilInst:CxGghCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class CxGghCardUtil extends BaseBean{
    public dataObj:ICxGghCardDataObj;

    constructor(proxy:any,dataObj:ICxGghCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //查询所有品牌车型
        this.dataObj.otherParams.brandData=await this.utils.OrderProviderApi.getAllBrand({});
        this.dataObj.otherParams.cxData=await this.utils.OrderProviderApi.getAllCx({});
    }
}